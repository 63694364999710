/* Additional CSS elements */

.headerlinks {
  font-family: 'Arvo', sans-serif;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-primary);
}

.navbar .posizer {
  margin-top: 10px;
  font-size: 16px;
  margin-left: 10px;
  margin-right: -8px;
}

.headerlinks:hover {
  opacity: 0.8;
}
.headerlinks:focus {
  font-style: normal;
}

.headerlinks:active{
  font-style: normal;
}

.headerlinks.active {
  font-weight: bold;
}



.linkitem {
  padding-left: 10px;
  padding-right: 10px;
}

.navbar {
  background-color: var(--color-secondary);
  border-bottom: 1px solid var(--color-primary);
  z-index: 9999;
  /* position: fixed;   */
  top: 0px;
  left: 0;
  width: 100%;
}

.keyvisual {
  display: flex;
  white-space: nowrap;
}

.keyvisual .logo {
  text-decoration: none;
  margin-top: 10px;
  margin-left: 20px;
}

.navtoggle {
  margin-right: 20px;
}



/* ---- small size <1022px --- */

@media screen and (max-width: 1022px) {
  .linkitem {
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 0px;
  }
}