@font-face {
  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arvo"),
    url(https://fonts.gstatic.com/s/arvo/v13/tDbD2oWUg0MKqScQ7Z7o_vo.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  font-family: "Arvo", sans-serif;
  scroll-behavior: smooth;
  --color-text: #000000;
  --color-primary: #4161a1;
  --color-secondary: #ffffff;
  --color-third: #e5e8ec;
  --color-fourth: #dee390;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Arvo", sans-serif;
  font-size: 12px;
}

li {
  font-family: "Arvo", sans-serif;
  font-size: 12px;
}

ul.opt {
  margin-left: -20px;
}

.helper {
  border: 3px solid rgb(228, 24, 24);
}


/* Text Elements */

.dsm-h3resize {
  font-family: "Arvo", sans-serif;
  font-size: 1.3em;
}

.dsm-h4resize {
  font-family: "Arvo", sans-serif;
  font-size: 1.05em;
}

.dsm-pmax {
  font-family: "Arvo", sans-serif;
  font-size: 13px;
  color:var(--color-primary);
}




/* Color Elements */

.dsm-bgcolor-pri {
  background-color: var(--color-primary);
}
.dsm-bgcolor-sec {
  background-color: var(--color-secondary);
}

.dsm-txtcolpri {
  color: var(--color-primary);
}

.dsm-txtcolsec {
  color: var(--color-secondary);
}

.dsm-type-blue{
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.dsm-type-white{
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.dsm-blocktext {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20em;
  text-align: center;
}

/* General Design Elements */

.dsm-container-resize {
  padding-top: 20px;
  padding-bottom: 20px;
}
.dsm-fullcontainer-resize {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.dsm-maillink {
  font-family: 'Arvo', sans-serif;
  font-style: normal;
  text-decoration: underline;
  color: var(--color-primary);
}

.dsm-maillink-white {
  font-family: 'Arvo', sans-serif;
  font-style: normal;
  text-decoration: underline;
  color: var(--color-secondary);
}

.custum-cookieconsent {
  font-family: 'Arvo', sans-serif;
}

/* is possible not used */
.dsm-sectdev { 
  height: 80px;
  width: 100%;
  float: left;
  bottom: 0;
}

button {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: none;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  /* box-shadow: 0 6px 8px 0 rgba(0,0,0,0.12), 0 8px 25px 0 rgba(0,0,0,0.5); */
  transition-duration: 0.4s;
  opacity: 0.9;
}

button:hover {
  opacity: 1; 
  border: none;
  background-color: white;
}