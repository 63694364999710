/* ---- general screen size --- */
@media screen {
  .footer {
    /* margin-top: 1rem;
    padding: 1rem; */
    padding-top: 20px;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    position: fixed; 
    bottom: 0px;
    left: 0; 
    width: 100%;
    /* position: sticky; */
  }

  .footerlinks {
    color: var(--color-secondary);
    text-decoration: none;
    font-size: 11px;
  }

  .footerlinks:hover {
    text-decoration: none;
    color: var(--color-secondary);
    opacity: 0.8;
  }
  .footerlinks:focus,
  .footerlinks:active,
  .footerlinks.active {
    font-weight: bold;
  }

  .footer-floating {
    background-color: var(--color-primary);
    padding-bottom: 10px;
    padding-top: -30px;
    border-style: solid;
    border-color: brown;
}

.footer-bottom-el {
  margin-bottom: 10px;
}

.footer-top-el  {
  margin-top: -10px;
}

.footer-top-linie {
  border-top: 1px solid rgb(255, 255, 255);
}

}

/* ---- small size <1022px --- */
@media screen and (max-width: 1022px) {
}
