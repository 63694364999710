/*  Animation Object */
.spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid var(--color-primary);
  border-color: var(--color-primary) transparent;
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

/* Amination code */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
