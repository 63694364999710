/* ---- general screen size --- */
@media screen {
  .sociallink {
    text-decoration: none;
    margin-right: 20px;
    margin-bottom: 5px;
  }

  .sociallink.active {
    text-decoration: none;
  }

  .icons-pri {
    color: var(--color-primary);
    background-color: var(--color-secondary);
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .icons-pri:focus {
    color: var(--color-primary);
    background-color: var(--color-secondary);
  }

  .icons-pri:hover {
    color: var(--color-fourth);
  }

  .icons-sec {
    color: var(--color-secondary);
    background-color: var(--color-primary);
  }

  .icons-sec:hover {
    color: var(--color-fourth);
    background-color: var(--color-primary);
  }

  .icons-sec:focus {
    color: var(--color-secondary);
    background-color: var(--color-primary);
  }

}

/* ---- small size <1022px --- */

@media screen and (max-width: 1022px) {
  .sociallink {
    
    margin-left: 30px;
    margin-right: 10px;
  }
  .sociallink .icons {
    margin-top: 20px;
  }
}
