.buttonLanguage {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);;
    padding: 1px 3px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-size: 12px;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.12), 0 8px 25px 0 rgba(0,0,0,0.5);
    transition-duration: 0.4s; 
    opacity: 0.8;
    margin-left: 20px;
    margin-right: 20px;
}

.buttonLanguage:hover,
.buttonLanguage:active {
    opacity: 0.8; 
    border: 1px solid rgb(228, 215, 215);
    background-color: var(--color-primary);
    color: var(--color-secondary);
  }